import React, { useEffect, useRef, useState } from "react";
import { Peticion } from "../helpers/Peticion";
import { Global } from "../helpers/Global";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

export const Admin = () => {
  const [clientes, setClientes] = useState([]);

  const conseguirClientes = async () => {
    let { datos } = await Peticion(Global.url + "clientes", "GET");

    if (datos.status === "success") {
      setClientes(datos.cliente);
    }
  };

  useEffect(() => {
    conseguirClientes();
  }, []);

  const tableRef = useRef(null);

  return (
    <div className="principal">
      <div className="jumbo-admin">
        <DownloadTableExcel
          filename="Clientes Los Lagos"
          sheet="Clientes"
          currentTableRef={tableRef.current}
        >
          <button className="btn-icon">
            <FontAwesomeIcon className="icon" icon={faFileExcel} />
          </button>
        </DownloadTableExcel>

        <table className="table" ref={tableRef}>
          <tr>
            <th>Nombre</th>
            <th>Apellidos</th>
            <th>Correo </th>
            <th>Telefono</th>
            <th>Direccion</th>
            <th>Barrio</th>
            <th>Ciudad</th>
            <th>Año</th>
            <th>Estado Civil</th>
            <th>Anfitrion</th>
            <th>Fecha de solicitud</th>
          </tr>

          {clientes.map((cliente) => {
            return (
              <tr>
                <td>{cliente.name}</td>
                <td>{cliente.lastname}</td>
                <td>{cliente.mail}</td>
                <td>{cliente.phone}</td>
                <td>{cliente.address}</td>
                <td>{cliente.district}</td>
                <td>{cliente.city}</td>
                <td>{cliente.year}</td>
                <td>{cliente.status_civil}</td>
                <td>{cliente.anfitrion}</td>
                <td>{cliente.date}</td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};
