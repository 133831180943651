import { Router } from './routers/Router';
import './assets/css/botones.css'
import './assets/css/reponsive.css'
import './App.css';

function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
