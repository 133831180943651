import React, { useRef, useState } from "react";
import { Global } from "../helpers/Global";
import { Peticion } from "../helpers/Peticion";
import { useForm } from "../hooks/useForm";
import { useMediaQuery } from "react-responsive";
import SlotCounter from "react-slot-counter";
import img1 from "../assets/images/2.png";
import img2 from "../assets/images/3.png";
import img4 from "../assets/images/4.png";
import img3 from "../assets/images/cabaña_green.png";
import winnerImg from "../assets/images/20240216_1220221.gif";
import Popup from "reactjs-popup";

const img = img1;

export const Home = () => {
  // const { data, mustSpin, handleSpinClick, prizeNumber, setMustSpin } =
  //   useRuleta({});

  const { formulario, cambiado } = useForm({});
  const [resultado, setResultado] = useState("no_enviado");
  const [winner, setWinner] = useState(img);
  const counterRef = useRef(null);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const handleStartClick = () => {
    counterRef.current?.startAnimation({
      // duration: 0.5,
      // dummyCharacterCount: 50,
      // utilizarMonospaceWidth: false,
      // direction: "top-up",
      // animateUnchanged: true,
      // autoAnimationStart: false,
    });
  };

  const guardarCliente = async (e) => {
    e.preventDefault();

    // recoger datos del formulario
    let nuevoCliente = formulario;

    // guardar articulos en el Backend
    const { datos } = await Peticion(
      Global.url + "crear",
      "POST",
      nuevoCliente
    );

    console.log(datos.articulo.year);

    if (datos.articulo.year > 25) {
      const img = img3;
      setWinner(img);
    } else {
      const img = img2;
      setWinner(img);
    }

    if (datos.status === "success") {
      setResultado("guardado");
    } else {
      setResultado("error");
    }
  };

  return (
    <>
      {isDesktopOrLaptop && (
        <div className="principal">
          <div className="jumbo">
            {/* {isDesktopOrLaptop && <div className="jumbo"><p>You are a desktop or laptop</p>}
        {isTabletOrMobile && <div className="jumbo-mobil"><p>You are a tablet or mobile phone</p>} */}

            {/* <h1>Traslados</h1> */}

            <strong className="alert-success">
              {resultado === "guardado"
                ? "Gracias por registrarte con nosotros, ahora participa gana"
                : ""}
            </strong>
            <strong className="alert-dark">
              {resultado === "error" ? "Faltan datos " : ""}
            </strong>

            <form className="form-group" onSubmit={guardarCliente}>
              <div className="box-form">
                <div className="d-cont">
                  {/* <label className="name" htmlFor="name">
                Nombre
              </label> */}
                  <input
                    type="text"
                    name="name"
                    placeholder="Nombre"
                    required
                  />
                </div>

                <div className="d-cont">
                  {/* <label htmlFor="lastname">Apellido</label> */}
                  <input
                    type="text"
                    name="lastname"
                    placeholder="Apellidos"
                    required
                  />
                </div>
              </div>

              <div className="box-form">
                <div className="d-cont">
                  {/* <label htmlFor="year">Edad</label> */}
                  <input type="text" name="year" placeholder="Edad" required />
                </div>

                <div className="d-cont">
                  {/* <label htmlFor="status_civil">Estado Civil</label> */}
                  <input
                    type="text"
                    name="status_civil"
                    placeholder="Estado Civil"
                    required
                  />
                </div>
              </div>

              <div className="box-form">
                <div className="d-cont">
                  {/* <label htmlFor="city">Ciudad</label> */}
                  <input
                    type="text"
                    name="city"
                    placeholder="Ciudad"
                    required
                  />
                </div>

                <div className="d-cont">
                  {/* <label htmlFor="district">Barrio</label> */}
                  <input
                    type="text"
                    name="district"
                    placeholder="Barrio"
                    required
                  />
                </div>
              </div>

              <div className="box-form">
                <div className="d-cont">
                  {/* <label htmlFor="address">Direccion</label> */}
                  <input
                    type="text"
                    name="address"
                    placeholder="Direccion"
                    required
                  />
                </div>
                <div className="d-cont">
                  {/* <label htmlFor="phone">Telefono</label> */}
                  <input
                    type="tel"
                    name="phone"
                    placeholder="Telefono"
                    required
                  />
                </div>
              </div>

              <div className="box-form">
                <div className="d-cont">
                  {/* <label htmlFor="mail">Correo</label> */}
                  <input
                    type="email"
                    name="mail"
                    placeholder="Correo"
                    required
                  />
                </div>
                <div className="d-cont">
                  {/* <label htmlFor="district">Barrio</label> */}
                  <input
                    type="text"
                    name="occupation"
                    placeholder="Profesión/Ocupación"
                    required
                  />
                </div>
              </div>

              <div>
                {/* <label htmlFor="anfitrion">Nombre Anfitrion</label> */}
                <input
                  type="text"
                  name="anfitrion"
                  placeholder="Nombre Anfitrion"
                  required
                />
              </div>

              <input type="submit" className="btn" value="Registrate" />
            </form>
          </div>
          <div className="info">
            <p>
              Juega, participa y gana una cabaña campestre en el Condominio
              Campestre Los Lagos.
            </p>
          </div>

          <div className="ruleta">
            <SlotCounter
              ref={counterRef}
              startValueOnce
              // animateUnchanged
              duration={7.9}
              dummyCharacterCount={100}
              utilizarMonospaceWidth={false}
              direction="top-up"
              animateUnchanged={true}
              autoAnimationStart={false}
              // sequentialAnimationMode
              charClassName="slot"
              // valueClassName="slot-value"
              // separatorClassName="slot-sep"
              value={[
                <img className="img-slot" src={img3} alt="Cabaña" />,
                <img className="img-slot" src={img3} alt="Cabaña" />,
                <img className="img-slot" src={img3} alt="Cabaña" />,
              ]}
              startValue={[
                <img className="img-slot" src={img1} alt="Cabaña" />,
                <img className="img-slot" src={img2} alt="Cabaña" />,
                <img className="img-slot" src={img3} alt="Cabaña" />,
              ]}
              dummyCharacters={[
                <img className="img-slot" src={img2} alt="" />,
                <img className="img-slot" src={img1} alt="" />,
                <img className="img-slot" src={img4} alt="" />,
                <img className="img-slot" src={img2} alt="" />,
                <img className="img-slot" src={img1} alt="" />,
                <img className="img-slot" src={img3} alt="" />,
                <img className="img-slot" src={img2} alt="" />,
                <img className="img-slot" src={img1} alt="" />,
                <img className="img-slot" src={img3} alt="" />,
              ]}
            />
            <button className="btn-play" onClick={handleStartClick}>
              Play
            </button>
          </div>
        </div>
      )}

      {isTabletOrMobile && (
        <div className="principal">
          <div className="jumbo-movil">
            {/* {isDesktopOrLaptop && <div className="jumbo"><p>You are a desktop or laptop</p>}
        {isTabletOrMobile && <div className="jumbo-mobil"><p>You are a tablet or mobile phone</p>} */}

            {/* <h1>Traslados</h1> */}
            <strong className="alert-success">
              {resultado === "guardado"
                ? "Cliente  registrado con exito !!"
                : ""}
            </strong>
            <strong className="alert-dark">
              {resultado === "error" ? "Faltan datos " : ""}
            </strong>
            {/* {isDesktopOrLaptop && <p>You are a desktop or laptop</p>}
        {isTabletOrMobile && <p>You are a tablet or mobile phone</p>} */}

            <form className="form-group" onSubmit={guardarCliente}>
              <div className="box-form">
                <div className="d-cont">
                  {/* <label className="name" htmlFor="name">
                Nombre
              </label> */}
                  <input
                    type="text"
                    name="name"
                    onChange={cambiado}
                    placeholder="Nombre"
                    required
                  />
                </div>

                <div className="d-cont">
                  {/* <label htmlFor="lastname">Apellido</label> */}
                  <input
                    type="text"
                    name="lastname"
                    onChange={cambiado}
                    placeholder="Apellidos"
                    required
                  />
                </div>
              </div>

              <div className="box-form">
                <div className="d-cont">
                  {/* <label htmlFor="year">Edad</label> */}
                  <input
                    type="text"
                    name="year"
                    onChange={cambiado}
                    placeholder="Edad"
                    required
                  />
                </div>

                <div className="d-cont">
                  {/* <label htmlFor="status_civil">Estado Civil</label> */}
                  <input
                    type="text"
                    name="status_civil"
                    onChange={cambiado}
                    placeholder="Estado Civil"
                    required
                  />
                </div>
              </div>

              <div className="box-form">
                <div className="d-cont">
                  {/* <label htmlFor="city">Ciudad</label> */}
                  <input
                    type="text"
                    name="city"
                    onChange={cambiado}
                    placeholder="Ciudad"
                    required
                  />
                </div>

                <div className="d-cont">
                  {/* <label htmlFor="district">Barrio</label> */}
                  <input
                    type="text"
                    name="district"
                    onChange={cambiado}
                    placeholder="Barrio"
                    required
                  />
                </div>
              </div>

              <div className="box-form">
                <div className="d-cont">
                  {/* <label htmlFor="address">Direccion</label> */}
                  <input
                    type="text"
                    name="address"
                    onChange={cambiado}
                    placeholder="Direccion"
                    required
                  />
                </div>
                <div className="d-cont">
                  {/* <label htmlFor="phone">Telefono</label> */}
                  <input
                    type="tel"
                    name="phone"
                    onChange={cambiado}
                    placeholder="Telefono"
                    required
                  />
                </div>
              </div>

              <div className="box-form">
                <div className="d-cont">
                  {/* <label htmlFor="mail">Correo</label> */}
                  <input
                    type="email"
                    name="mail"
                    onChange={cambiado}
                    placeholder="Correo"
                    required
                  />
                </div>
                <div className="d-cont">
                  {/* <label htmlFor="district">Barrio</label> */}
                  <input
                    type="text"
                    name="occupation"
                    onChange={cambiado}
                    placeholder="Profesión/Ocupación"
                    required
                  />
                </div>
              </div>
              <div className="box-form">
                <div className="d-cont">
                  {/* <label htmlFor="anfitrion">Nombre Anfitrion</label> */}
                  <input
                    type="text"
                    name="anfitrion"
                    onChange={cambiado}
                    placeholder="Nombre Anfitrion"
                    required
                  />
                </div>
              </div>

              <input type="submit" className="btn" value="Registrate" />
            </form>
          </div>
          <div className="info-mobil">
            <p>
              Juega, participa y gana una cabaña campestre en el Condominio
              Campestre Los Lagos.
            </p>
          </div>

          <div className="ruleta-mobil">
            <SlotCounter
              ref={counterRef}
              charClassName="slot-mobil"
              valueClassName="slot-value"
              separatorClassName="slot-sep"
              startValueOnce
              // animateUnchanged
              duration={7.9}
              dummyCharacterCount={100}
              utilizarMonospaceWidth={false}
              direction="top-up"
              animateUnchanged={true}
              autoAnimationStart={false}
              // sequentialAnimationMode
              // valueClassName="slot-value"
              // separatorClassName="slot-sep"

              value={[
                <img className="img-slot-mobil" src={winner} alt="Cabaña" />,
                <img className="img-slot-mobil" src={winner} alt="Cabaña" />,
                <img className="img-slot-mobil" src={winner} alt="Cabaña" />,
              ]}
              startValue={[
                <img className="img-slot-mobil" src={img1} alt="Cabaña" />,
                <img className="img-slot-mobil" src={img2} alt="Cabaña" />,
                <img className="img-slot-mobil" src={img3} alt="Cabaña" />,
              ]}
              dummyCharacters={[
                <img className="img-slot-mobil" src={img2} alt="" />,
                <img className="img-slot-mobil" src={img1} alt="" />,
                <img className="img-slot-mobil" src={img4} alt="" />,
                <img className="img-slot-mobil" src={img2} alt="" />,
                <img className="img-slot-mobil" src={img1} alt="" />,
                <img className="img-slot-mobil" src={img3} alt="" />,
              ]}
            />
            <button className="btn-play" onClick={handleStartClick}>
              Play
            </button>

            {/* <Popup trigger={<button>Trigger</button>} position="center center">
              <div className="pop-up">
                <img src={winnerImg} alt="Ganador" />
              </div>
            </Popup> */}
          </div>
        </div>
      )}
    </>
  );
};
