import React from "react";
import { useMediaQuery } from "react-responsive";

export const Footer = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <>
      {isDesktopOrLaptop && (
        <footer className="footer">
          Conceptos Inmobiliarios | Creado por &copy;
          <a href="https://gegova.com.co" target="_blank" rel="noreferrer">
            gegova.com.co
          </a>
        </footer>
      )}
      {isTabletOrMobile && (
        <footer className="footer-mobil">
          Conceptos Inmobiliarios | Creado por &copy;
          <a href="https://gegova.com.co" target="_blank" rel="noreferrer">
            gegova.com.co
          </a>
        </footer>
      )}
    </>
  );
};
