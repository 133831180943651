import React from "react";
import logo from "../../assets/images/logo.png";
import { useMediaQuery } from "react-responsive";

export const Header = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <>
      {isDesktopOrLaptop && (
        <header className="header">
          <h1>Bienvenidos</h1>
          <img src={logo} alt="Condominio Campestre los lagos" />
        </header>
      )}
      {isTabletOrMobile && (
        <header className="header-mobil">
          <h1>Bienvenidos</h1>
          <img src={logo} alt="Condominio Campestre los lagos" />
        </header>
      )}
    </>
  );
};
