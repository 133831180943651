import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "../components/Home";
import { Error } from "../components/Error";
import { Header } from "../components/layout/Header";
import { Footer } from "../components/layout/Footer";
import { Admin } from "../components/Admin";

export const Router = () => {
  return (
    <BrowserRouter>
      <Header />
      <section className="content" id="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </section>
      <Footer />
    </BrowserRouter>
  );
};
